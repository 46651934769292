import React from 'react';

export default () => {
    return (
        <>
            <i>&nbsp;</i>
            <i>&nbsp;</i>
            <i>&nbsp;</i>
            <i>&nbsp;</i>
            <i>&nbsp;</i>
            <i>&nbsp;</i>
            <i>&nbsp;</i>
        </>
    );
};
