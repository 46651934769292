import React from 'react';

export default (props) => {
    return (
        <svg
            fill = 'none'
            height = { 64 }
            viewBox = '0 0 63 64'
            width = { 63 }
            { ...props }
        >
            <path
                d = 'M37.5 45c-1.5 0-2.7-1.2-2.7-2.7V21.2c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7v21.1c0 1.5-1.2 2.7-2.7 2.7zM25.5 45c-1.5 0-2.7-1.2-2.7-2.7V21.2c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7v21.1c0 1.5-1.2 2.7-2.7 2.7z'
                fill = '#fff'
            />
            <path
                d = 'M31.5 62.3C48.345 62.3 62 48.645 62 31.8S48.345 1.3 31.5 1.3 1 14.955 1 31.8s13.655 30.5 30.5 30.5z'
                stroke = '#fff'
                strokeLinecap = 'round'
                strokeMiterlimit = { 10 }
                strokeWidth = { 2 }
            />
        </svg>
    );
};
