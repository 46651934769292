import React, { useState } from 'react';

export default ({ active, ...props }) => {
    const [ entered, setEntered ] = useState(false);

    return (
        <svg
            fill = 'none'
            height = { 64 }
            style = {{
                opacity: entered ? 0.8 : 1,
            }}
            viewBox = '0 0 64 64'
            width = { 64 }
            onMouseEnter = { () => setEntered(true) }
            onMouseLeave = { () => setEntered(false) }
            { ...props }
        >
            <path
                d = 'M38.9 47.9c-.3 0-.6-.1-.9-.2l-12.5-7.8h-5.2c-2.2 0-4-1.8-4-4v-8.1c0-2.2 1.8-4 4-4h5.2L38 16c.8-.5 1.8-.2 2.2.5.2.3.2.6.2.9v4.8c0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6v-1.9l-10.5 6.5c-.3.2-.6.2-.9.2h-5.7c-.4 0-.8.4-.8.8v8.1c0 .4.4.8.8.8h5.7c.3 0 .6.1.9.2l10.5 6.5V18c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6v28.3c0 .6-.3 1.1-.8 1.4-.1.2-.4.2-.7.2z'
                fill = { active ? '#6CD4F4' : '#fff' }
            />
            <path
                d = 'M31.7 62.4c16.9 0 30.6-13.7 30.6-30.6 0-16.9-13.7-30.6-30.6-30.6-16.9 0-30.6 13.7-30.6 30.6 0 16.9 13.7 30.6 30.6 30.6z'
                stroke = { active ? '#6CD4F4' : '#fff' }
                strokeLinecap = 'round'
                strokeMiterlimit = { 10 }
                strokeWidth = { 2 }
            />
        </svg>
    );
};
