import React from 'react';
import { MemoryRouter as Router, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Layout from './components/Layout';
import * as routes from './const/routes';

import Home from './screens/Home';
import View from './screens/View';
import Info from './screens/Info';

function App({location, ...props}) {
    return (
        <Router>
            <Layout>
                <Route render = { ({ location, ...props }) => (
                    <TransitionGroup style = {{height: '100%'}}>
                        <CSSTransition
                            classNames = 'routefade'
                            key = { location.key }
                            timeout = { 500 }
                        >
                            <Switch location = { location }>
                                <Route
                                    exact
                                    component = { Home }
                                    path = { routes.HOME }
                                />

                                <Route
                                    exact
                                    component = { Info }
                                    path = { routes.INFO }
                                />

                                <Route
                                    exact
                                    component = { View }
                                    path = { routes.VIEW }
                                />
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                ) } />
            </Layout>
        </Router>
    );
}

export default App;
