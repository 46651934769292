import React from 'react';
const ui = {
	loaded: false,
    darkBg: false,
    toggleDarkBg: () => {},
    playOverSound: () => {},
    playClickSound: () => {},
    playOverSound1: () => {},
    playClickSound1: () => {},
}

const UiContext = React.createContext(ui)

// This function takes a component...
export function withUiContext(Component) {
  // ...and returns another component...
  return function UiComponent(props) {
    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <UiContext.Consumer>
        {ui => <Component {...props} ui={ui} />}
      </UiContext.Consumer>
    );
  };
}

export default UiContext
