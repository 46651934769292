import React from 'react';
import cx from 'classnames';

// Instruments
import { withUiContext } from '../../../UiContext';

// Constants
import Languages from '../../../const/languages';

const Item = ({ id, alias, current, onClickSectionHandler, ui, ...props }) => {
    const locales = Languages[ ui.locale ].sections;
    const { playOverSound, playClickSound } = ui;
    const handleMouseClick = () => {
        if (current) {
            return;
        }

        playClickSound();
        onClickSectionHandler(id);
    };
    const handleMouseEnter = () => {
        if (current) {
            return;
        }

        playOverSound();
    };

    return (
        <div
            className = { cx({
                'slide-content': true,
                current,
            }) }
            onClick = { handleMouseClick }
            onMouseEnter = { handleMouseEnter }
            { ...props }
        >
            <img
                alt = { locales[ alias ].label }
                src = { require(`../../../assets/img/sections/${id}.jpg`) }
            />
        </div>
    );
};

export default withUiContext(Item);
