import React from 'react';

// Components
import { BackSound } from '../../components';

export default (props) => {
    return (
        <div
            className = 'volume-control'
            { ...props }
        >
            <BackSound />
        </div>
    );
};
