import React from 'react';
import { Counter } from '../parts';

export default ({ content, counter }) => {
    const { title, text } = content;

    return (
        <div className = 'section-description'>
            <Counter data = { counter } />

            <div className = 'section-description__title'>{title}</div>
            <div className = 'section-description__text'>{text}</div>
        </div>
    );
};
