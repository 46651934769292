import React from 'react';
import { NavLink } from 'react-router-dom';
import { withUiContext } from '../../UiContext';

const Control = (props) => {
    const { ui, onMouseEnter, onClick } = props;

    const playOverSound = () => ui.playOverSound();

    const playClickSound = () => ui.playClickSound();

    const handleMouseEnter = () => {
        playOverSound();

        if (onMouseEnter) {
            onMouseEnter();
        }
    };

    const handleClick = () => {
        playClickSound();

        if (onClick) {
            onClick();
        }
    };


    return (
        <NavLink
            onClick = { handleClick }
            onMouseEnter = { handleMouseEnter }
            { ...props }
        />
    );
};

export default withUiContext(Control);
