export default {
    ru: {
        label:    'Русский',
        sections: {
            AboutCompany: {
                label: 'Об институте',
                text:  'Научно\u2011исследовательский\u00A0институт\u00A0точных\u00A0приборов (АО\u00A0\u00ABНИИ\u00A0ТП\u00BB) объединяет профессиональных разработчиков и производителей разнообразных радиоэлектронных и программных продуктов.',
            },
            Services: {
                label: 'Услуги',
                text:  'Конструкторское, технологическое и метрологическое сопровождение.',
            },
            RadarMonitoringOfTheEarth: {
                label: 'Радиолокационный\nмониторинг Земли',
                text:  'Радиолокационные системы наблюдения Земли авиационного и космического базирования.',
            },
            RadioControlOfSpacecraft: {
                label: 'Радиоуправление\nкосмическими аппаратами',
                text:  'Комплексы автоматизированного управления космическими аппаратами.',
            },
            HighSpeedTransmissionOfSpaceInformation: {
                label: 'Высокоскоростная передача\nкосмической информации',
                text:  'Системы сбора, хранения и высокоскоростной передачи информации.',
            },
            ReceptionAndProcessingOfSpaceInformation: {
                label: 'Приём и обработка\nкосмической информации',
                text:  'Комплексы приёма, обработки, архивирования и доведения до потребителей информации дистанционного зондирования Земли.',
            },
            MassProductionOfGeospatialProducts: {
                label: 'Серийное изготовление\nгеопространственной продукции',
                text:  'Серийное изготовление геопространственной продукции от электронных топографических карт до трёхмерных моделей местности.',
            },
            SpaceCommunication: {
                label: 'Космическая связь',
                text:  'Радиотехнические комплексы для низкоорбитальных спутниковых систем связи, обеспечивающие глобальность передачи данных.',
            },
            DockingOfSpacecraft: {
                label: 'Стыковка космических\nаппаратов',
                text:  'Радиотехнические системы взаимных измерений для поиска, сближения и стыковки космических аппаратов.',
            },
        },
        dialog: {
            onExit: {
                header:             'Завершить работу презентации',
                buttonConfirmLabel: 'Да',
                buttonCancelLabel:  'Нет',
            },
            menu: {
                header: 'Меню',
            },
            video: {
                header: 'Видео',
            },
        },
    },

    en: {
        label:    'English',
        sections: {
            AboutCompany: {
                label: 'About\nthe Institute',
                text:  'The Scientific Research Institute of Precision Instruments (SRI PI) brings together professional developers and manufacturers of various electronic and software products.',
            },
            Services: {
                label: 'Services',
                text:  'Design, process and metrological support.',
            },
            RadarMonitoringOfTheEarth: {
                label: 'Radar Earth\nObservation',
                text:  'Airborne and space-based Earth observation radar systems.',
            },
            RadioControlOfSpacecraft: {
                label: 'Spacecraft radio control',
                text:  'Spacecraft automated control systems.',
            },
            HighSpeedTransmissionOfSpaceInformation: {
                label: 'High-speed transmission\nof space information',
                text:  'Systems for collection, storage and high-speed data transmission.',
            },
            ReceptionAndProcessingOfSpaceInformation: {
                label: 'Space information\nreception and processing',
                text:  'Earth remote sensing systems for reception, processing, filing and distribution of satellite data to the users.',
            },
            MassProductionOfGeospatialProducts: {
                label: 'Mass production\nof geospatial products',
                text:  'Serial production of geospatial products from electronic topographic maps to three-dimensional terrain models.',
            },
            SpaceCommunication: {
                label: 'Space communications',
                text:  'Radio engineering facilities for low-orbit satellite communication systems, ensuring the globality of data transmission.',
            },
            DockingOfSpacecraft: {
                label: 'Spacecraft docking',
                text:  'Radio engineering systems of mutual measurements for search, rendezvous and docking of spacecraft.',
            },
        },
        dialog: {
            onExit: {
                header:             'Complete the presentation',
                buttonConfirmLabel: 'Yes',
                buttonCancelLabel:  'No',
            },
            menu: {
                header: 'Menu',
            },
            video: {
                header: 'Video',
            },
        },
    },

    es: {
        label:    'Espanol',
        sections: {
            AboutCompany: {
                label: 'Sobre el Instituto',
                text:  'Instituto de Investigación Científica de Instrumentos de Precisión (NII TP) une a desarrolladores y fabricantes profesionales de diversos productos radioelectrónicos y de software.',
            },
            Services: {
                label: 'Servicios',
                text:  'Soporte de diseño, soporte tecnológico y metrológico.',
            },
            RadarMonitoringOfTheEarth: {
                label: 'Monitoreo de la\nTierra por radar',
                text:  'Sistemas de radar de observación de la Tierra desde aeronaves y desde el espacio.',
            },
            RadioControlOfSpacecraft: {
                label: 'Control por radio\nde naves espaciales',
                text:  'Complejos para la gestión automatizada de naves espaciales.',
            },
            HighSpeedTransmissionOfSpaceInformation: {
                label: 'Transmisión de alta velocidad\nde información espacial',
                text:  'Sistemas de recopilación, almacenamiento y transmisión de la información a alta velocidad.',
            },
            ReceptionAndProcessingOfSpaceInformation: {
                label: 'Recepción y procesamiento\nde la información cósmica',
                text:  'Complejos de recepción, procesamiento, archivado y transferencia a los consumidores de la información de teledetección terrestre.',
            },
            MassProductionOfGeospatialProducts: {
                label: 'Producción en serie\nde productos geoespaciales',
                text:  'Producción en serie de productos geoespaciales desde mapas topográficos electrónicos hasta modelos de terreno tridimensionales.',
            },
            SpaceCommunication: {
                label: 'Comunicación espacial',
                text:  'Complejos radio-técnicos para sistemas de comunicación por satélite de órbita baja, que aseguran la globalidad de la transmisión de datos.',
            },
            DockingOfSpacecraft: {
                label: 'Acoplamiento de naves espaciales',
                text:  'Sistemas radiotécnicos de mediciones mutuas para búsqueda, encuentro espacial y acoplamiento de naves espaciales.',
            },
        },
        dialog: {
            onExit: {
                header:             'Terminar la presentación',
                buttonConfirmLabel: 'Sí',
                buttonCancelLabel:  'No',
            },
            menu: {
                header: 'Menú',
            },
            video: {
                header: 'Vídeo',
            },
        },
    },

    zh: {
        label:    '中文',
        sections: {
            AboutCompany: {
                label: '研究所简介',
                text:  '精密仪器科学研究所汇集了各种电子和软件产品的专业开发人员和制造商。',
            },
            Services: {
                label: '服务',
                text:  '设计，技术和计量支持。',
            },
            RadarMonitoringOfTheEarth: {
                label: '雷达对地观测',
                text:  '航空和航天对地观测雷达系统',
            },
            RadioControlOfSpacecraft: {
                label: '航天器无线电控制',
                text:  '航天器自动控制系统。',
            },
            HighSpeedTransmissionOfSpaceInformation: {
                label: '空间信息的高速传输',
                text:  '高速数据采集存储系统。',
            },
            ReceptionAndProcessingOfSpaceInformation: {
                label: '空间信息接收与处理',
                text:  '接收、处理、归档和向用户分发卫星数据的地球遥感系统。',
            },
            MassProductionOfGeospatialProducts: {
                label: '大批量生产地理空间产品',
                text:  '从电子地形图到三维地形模型的地理空间产品的系列生产。',
            },
            SpaceCommunication: {
                label: '空间通信',
                text:  '用于低轨道卫星通信系统的无线电技术设施，确保数据传输的全球性。',
            },
            DockingOfSpacecraft: {
                label: '航天器对接',
                text:  '用于航天器搜索，接近和对接的相互测量的无线电工程系统。',
            },
        },
        dialog: {
            onExit: {
                header:             '完成演示文稿',
                buttonConfirmLabel: '是',
                buttonCancelLabel:  '否',
            },
            menu: {
                header: '菜单',
            },
            video: {
                header: '视频',
            },
        },
    },
};

