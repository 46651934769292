// Core
import React, { useState } from 'react';

// Parts
import { IconPlay, IconPause } from './parts';

export default ({ isPlayed, ...props }) => {
    const [ entered, setEntered ] = useState(false);

    return (
        <div
            style = {{
                opacity: entered ? 0.8 : 1,
            }}
            onMouseEnter = { () => setEntered(true) }
            onMouseLeave = { () => setEntered(false) }
            { ...props }
        >
            { isPlayed ? (
                <IconPause/>
            ) : (
                <IconPlay/>
            ) }
        </div>
    );
};
