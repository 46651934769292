import React, {Component} from 'react';
import {withUiContext} from '../../UiContext';
import Video from '../../components/Video';
import * as videos from '../../const/videos';
import './BackVideo.css';

const BgVideo = videos.BG;
const VIDEO_WIDTH = 16;
const VIDEO_HEIGHT = 9;

class BackVideo extends Component {
    state = {
        width:  0,
        height: 0,
    }

    componentDidMount() {
        this.setSize();
        window.addEventListener('resize', this.setSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setSize);
    }

    handleClick = () => {
        const {ui} = this.props;
        ui.toggleMenu();
    }

    setSize = () => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        let s_width = windowWidth;
        let width;
        let height;
        let expectedHeight = s_width / VIDEO_WIDTH * VIDEO_HEIGHT;

        if (windowHeight - expectedHeight > windowHeight) {
            height = windowHeight;
            width = windowHeight / VIDEO_HEIGHT * VIDEO_WIDTH;
        } else {
            width = s_width;
            height = expectedHeight;
        }

        this.setState({
            width:  width,
            height: height,
        });
    }

    render() {
        const { video, loop = true, background = false } = this.props;
        const _video = video || BgVideo;

        return (
            <Video
                background = { background }
                controls = { false }
                loop = { loop }
                video = { _video }
                volumeSwiper = { false }
            />
        );
    }
}

export default withUiContext(BackVideo);
