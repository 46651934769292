import React from 'react';
import { Video } from '../../components';
import { withUiContext } from '../../UiContext';
import { getVideoUrlBySectionId } from '../../lib/utils';
import * as routes from '../../const/routes';

// Parts
import { Wrap } from './parts';

const View = ({ ui, history }) => {
    const { sectionId, locale } = ui;
    const sectionVideoUrl = getVideoUrlBySectionId(sectionId, locale);
    const onEnded = () => {
        ui.setDriverStatus(false);
        history.push(routes.INFO);
    };

    return (
        <Wrap>
            <Video
                closeLink = { routes.INFO }
                video = { sectionVideoUrl }
                onEnded = { onEnded }
            />
        </Wrap>
    );
};

export default withUiContext(View);
