import React, { Component } from 'react';
import { withUiContext } from '../../UiContext';
import { getElementOffset } from '../../lib/utils';

import { VolumeLevels } from './parts';

class BackSound extends Component {
    componentDidMount = () => {
        const { ui } = this.props;
        const { playBackAudio, volume, isBackAudioMutedByUser } = ui;

        if (!isBackAudioMutedByUser) {
            playBackAudio();
        }

        this.changeVolume(volume.level);
    }

    toggleVolume = () => {
        const { ui } = this.props;
        const {
            backAudioPaused,
            toggleBackAudio,
            enableBackAudioUserControl,
            disableBackAudioUserControl,
        } = ui;

        toggleBackAudio();

        if (!backAudioPaused) {
            enableBackAudioUserControl();
        }

        if (backAudioPaused) {
            disableBackAudioUserControl();
        }
    }

    isDragging = false

    handleMousedown = () => {
        this.isDragging = true;
    }

    handleMouseup = () => {
        this.isDragging = false;
    }

    handleMousemove = (event) => {
        if (!this.isDragging) {
            return;
        }

        const percent = this.getMousePosition(event.clientY);

        this.changeVolume(percent);
    }

    getMousePosition = (clientY) => {
        // var windowHeight = window.innerHeight;
        const volumeHeight = this.line.offsetHeight;
        const volumeOffsetTop = getElementOffset(this.line).top;
        const innerY = volumeOffsetTop + volumeHeight - clientY;
        let percent = innerY / volumeHeight;

        if (percent < 0.2) {
            percent = 0;
        }

        if (percent > 0.8) {
            percent = 1;
        }

        return percent;
    }

    handleClick = (event) => {
        const percent = this.getMousePosition(event.clientY);

        this.changeVolume(percent);
    };

    changeFill = (percent) => {
        this.fill.style.height = `${percent * 100}%`;
    }

    changeVolume = (volume) => {
        const { ui } = this.props;
        const { backAudioSetVolume } = ui;

        backAudioSetVolume(volume);
        // this.setState({volume: volume})
        this.props.ui.volume.level = volume;

        this.forceUpdate(); // времянка

        this.changeFill(volume);
    }

    render() {
        const { ui } = this.props;
        const { backAudioPaused } = ui;

        // let volumeOn = volume > 0.01 // backAudioPaused ???

        return (
            <div
                className = 'volume-swiper'
            >
                <div
                    className = 'volume-swiper__slider volume-swiper__slider--line-veiw'
                    ref = { (line) => this.line = line }
                    onClick = { this.handleClick }
                    onMouseDown = { this.handleMousedown }
                    onMouseMove = { this.handleMousemove }
                    onMouseUp = { this.handleMouseup }
                >
                    <div className = 'volume-swiper__slider__empty'>
                        <VolumeLevels/>
                    </div>

                    <div
                        className = 'volume-swiper__slider__filled'
                        ref = { (fill) => this.fill = fill }
                    >
                        { !backAudioPaused && (
                            <VolumeLevels/>
                        ) }
                    </div>
                </div>
            </div>
        );
    }
}

export default withUiContext(BackSound);
