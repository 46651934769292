import React from 'react';

export default (props) => {
    return (
        <svg
            fill = 'none'
            height = { 342 }
            viewBox = '0 0 742 342'
            width = { 742 }
            { ...props }
        >
            <path
                d = 'M742 84.57V329.1c0 3.27-1.299 6.406-3.611 8.719a12.333 12.333 0 01-8.719 3.611H12.33A12.331 12.331 0 010 329.1V12.9A12.33 12.33 0 0112.33.57H658l84 84z'
                fill = '#E6E6E6'
                opacity = { 0.9 }
            />
        </svg>
    );
};
