import React from 'react';

// Instruments
import {withUiContext} from '../../../UiContext';

// Constants
import { Languages, Sections } from '../../../const';

// Components
import { NavLinkWithoutRoute } from '../../../components';
import { AimFrame } from '../../../components/SVG';

const MenuList = ({ ui, handleClick }) => {
    const sectionLocale = Languages[ ui.locale ].sections;

    return (
        <div className = 'menu-list'>
            <div className = 'grid__row'>
                {Sections.map((section) => (
                    <div
                        className = 'grid__col'
                        key = { section.id }
                    >
                        <NavLinkWithoutRoute
                            className = 'menu-list__item'
                            onClick = { () => handleClick(section.id) }
                        >
                            <div className = 'menu-list__item__thumb'>
                                <AimFrame/>

                                <img
                                    alt = { sectionLocale[ section.alias ].label }
                                    src = { require(`../../../assets/img/section-icons/${section.alias}.svg`) }
                                />
                                { sectionLocale[ section.alias ].icon }
                            </div>

                            <div className = 'menu-list__item__label'>
                                { sectionLocale[ section.alias ].label }
                            </div>
                        </NavLinkWithoutRoute>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default withUiContext(MenuList);
