import React from 'react';
import { NavLink } from '../../components';
import * as routes from '../../const/routes';

//
import Icon from './Icon';
import IconXs from './IconXs';

export default ({ small, to = routes.HOME, ...props }) => {
    return (
        <NavLink
            to = { to }
            { ...props }
        >
            { small ? (
                <IconXs/>
            ) : (
                <Icon/>
            ) }
        </NavLink>
    );
};
