import React, { useState } from 'react';
import cx from 'classnames';
import { Transition } from 'react-transition-group';

// Constants
import { Config } from '../../../const';
import * as routes from '../../../const/routes';
import Languages from '../../../const/languages';

// Instruments
import { withUiContext } from '../../../UiContext';

// Components
import {
    MenuSwitcher,
    VideoListSwitcher,
    BackToHomeButton,
    SectionsNavigator,
    Popup,
    VolumeSwitcher,
    VolumeControl,
    Logo,
} from '../../../components';

// Parts
import { MenuList, VideoList } from '../parts';

// Assets
import BgImage from '../../../assets/img/bg-info.png';

const Wrap = ({children, ui, history, ...props}) => {
    const { volume } = ui;
    const [ popupActivity, setPopupActivity ] = useState(false);
    const [ popupContentIdentifier, setPopupContentIdentifier ] = useState(null);
    const [ activityOfVolumeControl, setActivityOfVolumeControl ] = useState(false);
    const menuListOpeningHandler = () => {
        const { header } = Languages[ ui.locale ].dialog.menu;

        setPopupContentIdentifier('menu');
        setPopupActivity(true);
    };
    const videoListOpeningHandler = () => {
        const { header } = Languages[ ui.locale ].dialog.video;

        setPopupContentIdentifier('video');
        setPopupActivity(true);
    };
    const popupCloseHandler = () => {
        setPopupContentIdentifier(null);
        setPopupActivity(false);
    };

    return (
        <>
            <section
                className = { cx({
                    'hud hud-info': true,
                    'in-blur':      popupActivity,
                }) }
                { ...props }
            >
                <div
                    className = 'hud__bg'
                    style = {{
                        backgroundImage: `url("${BgImage}")`,
                    }}
                />

                <aside className = 'hud__sidebar'>
                    <div className = 'control-group'>
                        <BackToHomeButton
                            className = 'control-item'
                        />

                        <MenuSwitcher
                            className = 'control-item'
                            onClick = { menuListOpeningHandler }
                        />
                    </div>

                    <div className = 'control-group'>
                        <Transition
                            in = { activityOfVolumeControl }
                            timeout = { Config.transition.slideFromBottomToTop.duration }
                        >
                            {(transitionState) => (
                                <VolumeControl
                                    style = {{
                                        ...Config.transition.slideFromBottomToTop.defaultStyle,
                                        ...Config.transition.slideFromBottomToTop.statesStyle[ transitionState ],
                                    }}
                                    ui = { ui }
                                />
                            )}
                        </Transition>
                    </div>

                    <div className = 'control-group'>
                        <VolumeSwitcher
                            className = 'control-item'
                            disabled = { ui.volume.level === 0 }
                            piePercent = { volume.level * 100 }
                            onClick = { () => setActivityOfVolumeControl(!activityOfVolumeControl) }
                        />
                    </div>
                </aside>

                <main className = 'hud__content'>
                    {children}

                    <Logo className = 'hud__content__decor hud__content__decor-logo' />

                    <SectionsNavigator
                        history = { history }
                    />
                </main>
            </section>

            <Transition
                in = { popupActivity }
                timeout = { Config.transition.fade.duration }
            >
                {(transitionState) => (
                    <Popup
                        closeHandler = { popupCloseHandler }
                        style = {{
                            ...Config.transition.fade.defaultStyle,
                            ...Config.transition.fade.statesStyle[ transitionState ],
                        }}
                        title = { popupContentIdentifier }
                    >
                        { popupContentIdentifier && popupContentIdentifier === 'menu' && (
                            <MenuList
                                handleClick = { (id) => {
                                    popupCloseHandler();
                                    ui.setSectionId(id);
                                    // history.push(routes.INFO);
                                    console.log("Handle menu click", id)
                                    history.push(routes.VIEW);
                                } }
                            />
                        ) }

                        { popupContentIdentifier && popupContentIdentifier === 'video' && (
                            <VideoList
                                handleClick = { (id) => {
                                    popupCloseHandler();
                                    ui.setSectionId(id);
                                    history.push(routes.VIEW);
                                } }
                            />
                        ) }
                    </Popup>
                )}
            </Transition>
        </>
    );
};

export default withUiContext(Wrap);
