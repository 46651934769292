import React, { Component } from 'react';
import './_styles.scss';
import BgImage from '../../assets/img/bg-main.png';

export default class MobilePlaceholder extends Component {
    render() {
        return (
            <div
                className = 'mobile-placeholder'
                style = {{
                    backgroundImage: `url(${BgImage})`,
                }}
            >
                <div className = 'mobile-placeholder__inner'>
                    <div className = 'mobile-placeholder__text'>
                        <span>Презентация работает  при разрешении экрана <span className = '_nowrap'>1920х1080 пикселей</span> и выше. </span>
                        <span>Ваше устройство не поддерживает данное <span className = '_nowrap'>разрешение экрана.</span></span>
                    </div>
                </div>
            </div>
        );
    }
}
