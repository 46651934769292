import React from 'react';

export default (props) => {
    return (
        <svg
            fill = 'none'
            height = { 90 }
            viewBox = '0 0 90 90'
            width = { 90 }
            { ...props }
        >
            <g
                opacity = { 0.3 }
                stroke = '#0A4885'
                strokeMiterlimit = { 10 }
                strokeWidth = { 3 }
            >
                <path
                    d = 'M15.23 2.23H2.02l-.22.22v13.21M74.77 87.77h13.21l.22-.22V74.34M87.77 15.23V2.02l-.22-.22H74.34M2.23 74.77v13.21l.22.22h13.21'
                    opacity = { 0.3 }
                />
            </g>
        </svg>
    );
};
