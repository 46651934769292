const transitionDuration = 300;

export default {
    transition: {
        fade: {
            duration:     transitionDuration,
            defaultStyle: {
                transition: `opacity ${transitionDuration}ms ease-in-out`,
                opacity:    0,
                visibility: 'hidden',
            },
            statesStyle: {
                entering: {
                    opacity:    1,
                    visibility: 'visible',
                },
                entered: {
                    opacity:    1,
                    visibility: 'visible',
                },
                exiting: {
                    opacity:    0,
                    visibility: 'visible',
                },
                exited: {
                    opacity:    0,
                    visibility: 'hidden',
                },
            },
        },
        slideFromLeftToRight: {
            duration:     transitionDuration,
            defaultStyle: {
                transition: `all ${transitionDuration}ms ease-in-out, opacity 150ms ease-in-out`,
                opacity:    0,
                visibility: 'hidden',
            },
            statesStyle: {
                entering: {
                    opacity:    0,
                    visibility: 'visible',
                    left:       -300,
                },
                entered: {
                    opacity:    1,
                    visibility: 'visible',
                    left:       0,
                    right:      0,
                },
                exiting: {
                    opacity:    0,
                    visibility: 'visible',
                    right:      -300,
                },
                exited: {
                    opacity:    0,
                    visibility: 'hidden',
                    display:    'none',
                    right:      -300,
                },
            },
        },
        slideFromBottomToTop: {
            duration:     transitionDuration * 5,
            defaultStyle: {
                transition: `all ${transitionDuration}ms ease-in-out, opacity 150ms ease-in-out`,
                opacity:    0,
                visibility: 'hidden',
            },
            statesStyle: {
                entering: {
                    opacity:    1,
                    visibility: 'visible',
                    marginTop:  0,
                },
                entered: {
                    opacity:      1,
                    visibility:   'visible',
                    marginBottom: 0,
                },
                exiting: {
                    opacity:      0,
                    visibility:   'hidden',
                    marginBottom: -300,
                },
                exited: {
                    opacity:    0,
                    visibility: 'hidden',
                    marginTop:  -300,
                },
            },
        },
    },
    screensaver: {
        delay: 30000,
    },
};
