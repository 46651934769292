import React from 'react';

// Instruments
import { withUiContext } from '../../UiContext';

const NavLinkWithoutRoute = ({ children, ui, onMouseEnter, onClick, ...props }) => {
    const handleMouseEnter = () => {
        ui.playOverSound();

        if (onMouseEnter) {
            onMouseEnter();
        }
    };

    const handleMouseClick = (ev) => {
        ev.preventDefault();

        ui.playClickSound();

        if (onClick) {
            onClick();
        }
    };


    return (
        <a
            href = '#'
            onClick = { handleMouseClick }
            onMouseEnter = { handleMouseEnter }
            { ...props }
        >
            {children}
        </a>
    );
};

export default withUiContext(NavLinkWithoutRoute);
