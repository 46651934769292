import React from 'react';

// Instruments
import { withUiContext } from '../../UiContext';

// Components
import { Video } from '../../components';

const Screensaver = ({ ui, active }) => {
    const { locale } = ui;

    return (
        <div
            style = {{
                display:        active ? 'flex' : 'none',
                alignItems:     'center',
                justifyContent: 'center',
                position:       'fixed',
                zIndex:         10000,
                top:            0,
                right:          0,
                bottom:         0,
                left:           0,
            }}
        >
            { active && (
                <Video
                    background
                    loop
                    controls = { false }
                    video = {{
                        file:  `${process.env.PUBLIC_URL}/video/${locale}/Screensaver.mp4`,
                        vimeo_id: '474432723',
                        title: '',
                        name:  '',
                    }}
                    volumeSwiper = { false }
                    withLoader = { false }
                />
            ) }
        </div>
    );
};

export default withUiContext(Screensaver);
