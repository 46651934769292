import React from 'react';

export default ({ active, playClickSound, playOverSound, onClick, ...props}) => {
    const handleMouseEnter = () => playOverSound();
    const handleClick = () => {
        playClickSound();
        onClick();
    };

    return (
        <svg
            fill = 'none'
            height = { 63 }
            style = {{
                transform: `rotate(${active ? '180' : '0'}deg)`,
            }}
            viewBox = '0 0 26 63'
            width = { 26 }
            onClick = { handleClick }
            onMouseEnter = { handleMouseEnter }
            { ...props }
        >
            <g
                opacity = { 0.8 }
                stroke = '#fff'
                strokeLinecap = 'round'
                strokeLinejoin = 'round'
                strokeWidth = { 5 }
            >
                <path
                    d = 'M3.34 31.8L23.23 3.46M3.34 31.8l19.89 28.34'
                    opacity = { 0.8 }
                />
            </g>
        </svg>
    );
};
