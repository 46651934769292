import React from 'react';
import { pad } from '../../../lib/utils';

export default ({ data }) => {
    const { current, total } = data;

    return (
        <div className = 'section-description__counter'>
            {pad(current, 2)}/{pad(total, 2)}
        </div>
    );
};
