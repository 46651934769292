import React from 'react';
import cx from 'classnames';

export default ({ className, ...props }) => {
    return (
        <svg
            className = { cx(
                'btn-svg btn-svg--back-to-home--xs',
                className,
            ) }
            fill = 'none'
            height = { 82 }
            viewBox = '0 0 81 82'
            width = { 81 }
            { ...props }
        >
            <path
                d = 'M28.2 41H8l7-10M28.2 41H8l7 10'
                stroke = '#fff'
                strokeLinecap = 'round'
                strokeLinejoin = 'round'
                strokeMiterlimit = { 10 }
                strokeWidth = { 2 }
            />
            <path
                className = 'svg-decor-group-3'
                d = 'M23 16.8c4.8-3.4 10.8-5.4 17.1-5.4 16.4 0 29.7 13.3 29.7 29.7S56.5 70.8 40.1 70.8c-6.1 0-11.9-1.9-16.6-5.1'
                opacity = { 0.6 }
                stroke = '#fff'
                strokeLinecap = 'round'
                strokeLinejoin = 'round'
                strokeMiterlimit = { 10 }
                strokeWidth = { 2 }
            />
            <path
                className = 'svg-decor-group-2'
                d = 'M40.5 80.2c21.65 0 39.2-17.55 39.2-39.2 0-21.65-17.55-39.2-39.2-39.2C18.85 1.8 1.3 19.35 1.3 41c0 21.65 17.55 39.2 39.2 39.2z'
                opacity = { 0.3 }
                stroke = '#fff'
                strokeLinecap = 'round'
                strokeLinejoin = 'round'
                strokeMiterlimit = { 10 }
                strokeWidth = { 2 }
            />
        </svg>
    );
};
