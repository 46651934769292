export default [
    {
        id:    1,
        alias: 'AboutCompany',
    },
    {
        id:    2,
        alias: 'Services',
    },
    {
        id:    3,
        alias: 'RadarMonitoringOfTheEarth',
    },
    {
        id:    4,
        alias: 'RadioControlOfSpacecraft',
    },
    {
        id:    5,
        alias: 'HighSpeedTransmissionOfSpaceInformation',
    },
    {
        id:    6,
        alias: 'ReceptionAndProcessingOfSpaceInformation',
    },
    {
        id:    7,
        alias: 'MassProductionOfGeospatialProducts',
    },
    {
        id:    8,
        alias: 'SpaceCommunication',
    },
    {
        id:    9,
        alias: 'DockingOfSpacecraft',
    },
];

