// Core
import React, { useState } from 'react';
import { Transition } from 'react-transition-group';

// Constants
import { Config } from '../../../const';

// Instruments
import { withUiContext } from '../../../UiContext';

// Components
import {
    SettingsSwitcher,
    MenuSwitcher,
    VolumeSwitcher,
    VolumeControl,
    Popup,
    Logo,
} from '../../../components';

// Parts
import { LanguagesList, DialogOnExit } from '../parts';

// Assets
import BgImage from '../../../assets/img/bg-main.png';
import cx from 'classnames';

const Wrap = ({ children, ui, ...props }) => {
    const { volume } = ui;
    const [ popupActivity, setPopupActivity ] = useState(false);
    const [ passFormEnabled, setPassFormEnabled ] = useState(false);
    const [ activityOfLanguagesList, setActivityOfLanguagesList ] = useState(false);
    const [ activityOfVolumeControl, setActivityOfVolumeControl ] = useState(false);

    return (
        <>
            <section
                className = { cx({
                    'hud hud-hud-home': true,
                    'in-blur':          popupActivity,
                }) }
                { ...props }
            >
                <div
                    className = 'hud__bg'
                    style = {{
                        backgroundImage: `url("${BgImage}")`,
                    }}
                />

                <aside className = 'hud__sidebar'>
                    <div className = 'control-group'>
                        { false && (<SettingsSwitcher
                            className = 'control-item'
                            onClick = { () => setPopupActivity(true) }
                            /> 
                        ) }

                        { false && (
                            <MenuSwitcher
                                className = 'control-item'
                            />
                        ) }
                    </div>

                    <div className = 'control-group'>
                        <Transition
                            in = { activityOfLanguagesList }
                            timeout = { Config.transition.slideFromBottomToTop.duration }
                        >
                            {(transitionState) => (
                                <LanguagesList
                                    style = {{
                                        ...Config.transition.slideFromBottomToTop.defaultStyle,
                                        ...Config.transition.slideFromBottomToTop.statesStyle[ transitionState ],
                                    }}
                                    ui = { ui }
                                />
                            )}
                        </Transition>

                        <Transition
                            in = { activityOfVolumeControl }
                            timeout = { Config.transition.slideFromBottomToTop.duration }
                        >
                            {(transitionState) => (
                                <VolumeControl
                                    style = {{
                                        ...Config.transition.slideFromBottomToTop.defaultStyle,
                                        ...Config.transition.slideFromBottomToTop.statesStyle[ transitionState ],
                                    }}
                                    ui = { ui }
                                />
                            )}
                        </Transition>
                    </div>

                    <div className = 'control-group'>
                        <VolumeSwitcher
                            className = 'control-item'
                            disabled = { ui.volume.level === 0 }
                            piePercent = { volume.level * 100 }
                            onClick = { () => {
                                setActivityOfLanguagesList(false);
                                setActivityOfVolumeControl(!activityOfVolumeControl);
                            } }
                        />
                    </div>
                </aside>

                <main className = 'hud__content'>
                    {children}

                    <Logo className = 'hud__content__decor hud__content__decor-logo' />
                </main>
            </section>

            <Transition
                in = { popupActivity }
                timeout = { Config.transition.fade.duration }
            >
                {(transitionState) => (
                    <Popup
                        closeHandler = { () => {
                            setPopupActivity(false)
                            setTimeout( () => {setPassFormEnabled(false)}, 1000 )
                        } }
                        size = 'x-small'
                        style = {{
                            ...Config.transition.fade.defaultStyle,
                            ...Config.transition.fade.statesStyle[ transitionState ],
                        }}
                    >
                        <DialogOnExit
                            handlerOnCancel = { () => {
                                setPopupActivity(false)
                                setTimeout( () => {setPassFormEnabled(false)}, 1000 )
                            } }
                            handlerOnAgree = { () => setPassFormEnabled(true) }
                            handlerOnConfirm = { ui.closeWindow }
                            locale = { ui.locale }
                            passFormEnabled = { passFormEnabled }
                        />
                    </Popup>
                )}
            </Transition>
        </>
    );
};

export default withUiContext(Wrap);
