import React from 'react';
import { withUiContext } from '../../UiContext';

//
import { LogoRu, LogoEn } from '../SVG';

const Logo = ({ ui, ...props }) => {
    return (
        <>
            { ui.locale === 'ru' ? (
                <LogoRu { ...props } />
            ) : (
                <LogoEn { ...props } />
            ) }
        </>
    );
};

export default withUiContext(Logo);
