import React from 'react';

export default (props) => {
    return (
        <svg
            fill = 'none'
            height = { 680 }
            viewBox = '0 0 1556 680'
            width = { 1556 }
            { ...props }
        >
            <path
                d = 'M1555.31 84v575.49c0 5.44-2.16 10.656-6.01 14.503a20.493 20.493 0 01-14.5 6.007H21.21A20.52 20.52 0 01.69 659.49v-639A20.52 20.52 0 0121.21 0h1450.1l84 84z'
                fill = '#E6E6E6'
                opacity = { 0.95 }
            />
        </svg>
    );
};
