// Core
import React, {Component} from 'react';
import Range from 'react-rangeslider';

// Styles
import 'react-rangeslider/lib/index.css';

// Instruments
import { numberToHHMMSS } from '../../lib/utils';
import { withUiContext } from '../../UiContext';

// Components
import {
    PlayerControlSwitch,
    PlayToggleButton,
    VolumeToggleButton,
} from '../../components';

class VideoProgressBar extends Component {
    state = {
        progress:    0,
        currentTime: 0,
        duration:    0,
        visibility:  false,
        videoSrc:    '',
    }

    componentDidMount() {
        this.hookTimeUpdate();
    }

    componentDidUpdate() {
        this.hookTimeUpdate();
        this.hookVimeoTimeUpdate();
    }

    componentWillUnmount() {
        const { video, player } = this.props;

        if (player) {
            player.off('timeupdate');
        }

        if (!video || !video.addEventListener) {
            return;
        }

        video.removeEventListener('timeupdate', this.update);

        clearInterval(this.watchInterval);
    }

    hookTimeUpdate = () => {
        const { video } = this.props;

        if (!video || !video.addEventListener) {
            return;
        }

        const { src } = video.src;

        if (src === this.state.videoSrc) {
            return;
        }

        video.addEventListener('timeupdate', this.update);

        this.setState({
            videoSrc: src,
        });
    }

    hookVimeoTimeUpdate = () => {
        const { player } = this.props;

        if (player) {
            const { src } = player.element;

            if (src === this.state.videoSrc) {
                return;
            }

            this.setState({
                videoSrc: src,
            });

            this.watchInterval = setInterval(() => {
                player.getCurrentTime().then((seconds) => {
                    const { duration } = this.state;

                    if (!duration) {
                        return;
                    }

                    let progress = 100 / duration * seconds;
                    this.updateProgress(progress, seconds, duration);
                });
            }, 30);

            this.watchDurationInterval = setInterval(() => {
                player.getDuration().then((duration) => {
                    if (duration !== this.duration) {
                        this.duration = duration;
                        this.setState({duration: duration});
                    }
                });
            }, 30);
        }
    }

    update = () => {
        const { video } = this.props;
        let progress = 100 / video.duration * video.currentTime;
        this.updateProgress(progress, video.currentTime, video.duration);
    }

    updateProgress = (progress, currentTime, duration) => {
        this.setState({progress: progress, currentTime: currentTime, duration: duration});
    }

    handleChange = (value) => {
        const { video, player } = this.props;
        this.setState({progress: value});
        if (video) {
            video.currentTime = video.duration * (value / 100);
        }
        if (player) {
            player.getDuration().then((duration) => {
                player.setCurrentTime(duration * (value / 100));
            });
        }
    }

    handleChangeStart = () => {
        const { video, player } = this.props;

        if (video) {
            video.pause();
        }

        if (player) {
            player.pause();
        }
    }

    handleChangeComplete = () => {
        const { video, player } = this.props;

        if (video) {
            video.play();
        }

        if (player) {
            player.play();
        }
    }

    toMinutes = (seconds) => {
        return numberToHHMMSS(seconds);
    }

    handleVolumeChange = (volume) => {
        const { video, player } = this.props;

        if (video) {
            video.volume = volume;
        }

        if (player) {
            player.setVolume(volume);
        }
    }

    toggleVolume = () => {
        const { video, player } = this.props;

        if (video) {
            video.volume > 0.01 ? video.volume = 0 : video.volume = this.props.ui.volume.level;
        }

        if (player) {
            player.getVolume().then((volume) => {
                if (volume > 0.01) {
                    player.setVolume(0);
                } else {
                    player.setVolume(0.5);
                }
            });
        }
    }

    toggleVisible = () => {
        this.setState((prevState) => ({
            visibility: !prevState.visibility,
        }));
    }

    render() {
        const { progress, currentTime, duration, visibility } = this.state;
        const { onPauseBtnClick, paused, ui } = this.props;

        return (
            <div className = 'video-player__controls'>
                <div className = 'control-panel control-panel-side'>
                    <div className = 'control-group'>
                        <PlayerControlSwitch
                            className = 'control-item'
                            piePercent = { currentTime / duration * 100 || 0 }
                            onClick = { this.toggleVisible }
                        />
                    </div>
                </div>

                <div
                    className = 'control-panel control-panel-main'
                    style = {{
                        opacity:    visibility ? 1 : 0,
                        visibility: visibility ? 'visible' : 'hidden',
                    }}
                >
                    <div className = 'control-group control-group-first'>
                        <Range
                            max = { 100 }
                            min = { 0 }
                            value = { progress }
                            onChange = { this.handleChange }
                            onChangeComplete = { this.handleChangeComplete }
                            onChangeStart = { this.handleChangeStart }
                        />
                    </div>

                    <div className = 'control-group control-group-second'>
                        <PlayToggleButton
                            className = 'control-item'
                            isPlayed = { !paused }
                            onClick = { onPauseBtnClick }
                        />

                        <VolumeToggleButton
                            className = 'control-item'
                            volumeLevel = { ui.volume.level }
                            onVolumeChange = { this.handleVolumeChange }
                        />

                        <div className = 'control-info'>
                            <span>{this.toMinutes(currentTime)}</span>
                            <span>&nbsp;/&nbsp;</span>
                            <span>{this.toMinutes(duration)}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withUiContext(VideoProgressBar);
