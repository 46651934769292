import React from 'react';
import cx from 'classnames';

// Components
import {
    CirclesGroupMain,
    CirclesGroupFirst,
    CirclesGroupSecond,
    CirclesGroupThird,
} from '../../../components/SVG';

export default () => {
    return (
        <div className = 'main-menu__decor'>
            <div
                className = { cx(
                    'main-menu__decor__bg',
                    'animation',
                    'animation-name-rotate',
                    'animation-delay-025',
                    'animation-timing-linear',
                    'animation-duration-50',
                    'animation-iteration-infinite',
                ) }
            >
                <div className = 'decor-group'>
                    <CirclesGroupMain />
                </div>
            </div>

            <div className = 'main-menu__decor__shadow'>
                <div className = 'decor-group decor-group-1'>
                    <CirclesGroupFirst
                        className = { cx(
                            'animation',
                            'animation-name-rotate',
                            'animation-delay-025',
                            'animation-timing-linear',
                            'animation-duration-50',
                            'animation-iteration-infinite',
                        ) }
                    />
                </div>

                <div className = 'decor-group decor-group-2'>
                    <CirclesGroupSecond />
                </div>

                <div className = 'decor-group decor-group-3'>
                    <CirclesGroupThird
                        className = { cx(
                            'animation',
                            'animation-name-rotate-reverse',
                            'animation-delay-075',
                            'animation-timing-linear',
                            'animation-duration-50',
                            'animation-iteration-infinite',
                        ) }
                    />
                </div>
            </div>
        </div>
    );
};
