import React from 'react';
import cx from 'classnames';
import * as routes from '../../../const/routes';
import { withUiContext } from '../../../UiContext';
import { NavLink, BackVideo } from '../../../components';
import { getVideoUrlBySectionId } from '../../../lib/utils';
import { Grill as GrillDecor, IconPlay } from '../../../components/SVG';

const Preview = ({ ui }) => {
    const { sectionId, locale } = ui;
    const sectionVideoUrl = getVideoUrlBySectionId(sectionId, locale);

    return (
        <div className = {cx('section-preview', 'section-' + sectionId)}>
            {sectionVideoUrl && (
                <BackVideo
                    background
                    loop
                    video = { sectionVideoUrl }
                />
            )}

            <GrillDecor
                className = 'section-preview__bg'
            />

            <NavLink
                className = 'section-preview__btn'
                to = { routes.VIEW }
            >
                <IconPlay />
            </NavLink>
        </div>
    );
};

export default withUiContext(Preview);
