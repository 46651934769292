import React from 'react';

export default (props) => {
    return (
        <svg
            fill = 'none'
            height = { 332 }
            viewBox = '0 0 632 332'
            width = { 632 }
            { ...props }
        >
            <path
                d = 'M632 84.57V319.1c0 6.81-5.52 12.33-12.33 12.33H12.33C5.52 331.43 0 325.91 0 319.1V12.9C0 6.09 5.52.57 12.33.57H548l84 84z'
                fill = '#E6E6E6'
                opacity = { 0.9 }
            />
        </svg>
    );
};
