import React from 'react';

// Instruments
import { withUiContext } from '../../UiContext';

// Constants
import Languages from '../../const/languages';

// Parts
import { MenuSections, Decors } from './parts';

const MainMenu = ({ ui, onSelect }) => {
    const locales = Languages[ ui.locale ].sections;

    return (
        <div className = 'main-menu'>
            <div className = 'main-menu__inner'>
                <MenuSections
                    data = { locales }
                    handleMouseClick = { (id) => {
                        ui.playClickSound();
                        onSelect(id);
                    } }
                    handleMouseEnter = { ui.playOverSound }
                />
            </div>

            <Decors/>
        </div>
    );
};

export default withUiContext(MainMenu);
