import React from 'react';
import cx from 'classnames';

export default (props) => {
    return (
        <svg
            fill = 'none'
            height = { 711 }
            viewBox = '0 0 712 711'
            width = { 712 }
            { ...props }
        >
            <g opacity = { 0.2 }>
                <path
                    d = 'M356 107.137C436.24 107.137 512.76 136.686 571.46 190.255C642.46 255.083 660.19 329.602 664.2 353.282C660.2 377.03 642.41 451.548 571.46 516.308C512.76 569.946 436.24 599.426 356 599.426C275.76 599.426 199.24 569.946 140.54 516.308C69.59 451.548 51.81 377.03 47.8 353.282C51.8 329.602 69.59 255.083 140.54 190.255C199.24 136.481 275.76 107.137 356 107.137ZM356 38.8967C179.79 38.8967 37 179.677 37 353.282C37 526.886 179.84 667.667 356 667.667C532.16 667.667 675 526.954 675 353.282C675 179.609 532.16 38.8967 356 38.8967Z'
                    fill = '#6CD4F4'
                    opacity = { 0.2 }
                />
            </g>
            <g opacity = { 0.2 }>
                <path
                    d = 'M356 711C329 711 302 707.997 276 702.061L276.07 689.709C302.14 695.646 329.07 698.648 356.07 698.648C368.67 698.648 381.36 697.966 393.76 696.67V709.089C381.31 710.386 368.62 711 356 711ZM246 693.872C218 684.864 191.1 672.376 166.15 656.749L166.35 644.465C191.26 660.024 218.1 672.512 246.1 681.52L246 693.872ZM136.3 635.867C105.3 611.71 78.74 582.912 57.39 550.157L57.87 538.146C79.14 570.765 105.62 599.563 136.55 623.584L136.3 635.867ZM642.69 567.626L642.26 555.547C685.65 496.997 709.55 425.89 709.55 355.739C709.55 354.374 709.55 353.009 709.55 351.576L711.55 350.621C711.565 352.325 711.565 354.034 711.55 355.739C711.5 453.937 674.05 525.044 642.64 567.422L642.69 567.626ZM28.51 496.11C9.93001 452.368 0.51001 405.077 0.51001 355.534C0.51001 300.941 11.89 249.351 34.32 201.992L34.96 213.73C13.73 258.496 2.51001 307.561 2.51001 355.534C2.51001 398.799 11.75 443.428 29.23 484.577L28.51 496.11ZM688.41 240.617C671.47 195.237 645.51 154.429 611.23 119.353L611.56 107.138C645.99 142.35 672.12 183.431 689.2 229.221L688.41 240.617ZM63.76 163.778L63.31 151.767C85.07 120.513 111.66 93.0802 142.31 70.2195L142.55 82.5711C112 105.568 85.45 132.933 63.76 163.778ZM581.57 91.9884C557.33 72.2668 530.57 55.7526 501.99 42.9916L502.14 30.7083C530.74 43.4693 557.55 59.9153 581.83 79.7051L581.57 91.9884ZM172.39 62.5766L172.2 50.2934C197.26 35.2804 224.13 23.4066 252.07 14.9447L252.17 27.3646C224.26 36.0312 197.42 47.7687 172.39 62.8499V62.5766ZM472.06 31.2544C446.29 22.4513 419.39 16.6506 392.12 13.921V1.5011C419.42 4.23073 446.34 10.0998 472.12 18.9028L472.06 31.2544Z'
                    fill = 'white'
                    opacity = { 0.2 }
                />
            </g>
            <path
                className = { cx(
                    'animation',
                    'animation-name-fade-from-08-to-03',
                    'animation-delay-1',
                    'animation-timing-linear',
                    'animation-duration-3',
                    'animation-iteration-infinite',
                ) }
                d = 'M424.06 704L424 691.846C451.523 686.675 478.386 678.416 503.86 667L504 679.154C478.506 690.436 451.613 698.829 424.06 704Z'
                fill = '#6CD4F4'
                opacity = { 0.8 }
            />
            <path
                className = { cx(
                    'animation',
                    'animation-name-fade-from-08-to-05',
                    'animation-delay-025',
                    'animation-timing-linear',
                    'animation-duration-2',
                    'animation-iteration-infinite',
                ) }
                d = 'M533.191 664L533 651.61C562.385 634.838 589.239 614.028 612.669 590L613 602.253C589.47 626.35 562.676 647.228 533.191 664Z'
                fill = '#6CD4F4'
                opacity = { 0.8 }
            />
            <path
                className = { cx(
                    'animation',
                    'animation-name-fade-from-08-to-05',
                    'animation-delay-075',
                    'animation-timing-linear',
                    'animation-duration-1',
                    'animation-iteration-infinite',
                ) }
                d = 'M282.06 20L282 7.57671C306.115 2.5255 330.969 0 355.874 0H362V12.355H355.874C330.999 12.355 306.165 14.9488 282.06 20Z'
                fill = '#6CD4F4'
                opacity = { 0.8 }
            />
        </svg>
    );
};
