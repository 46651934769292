import React from 'react';

export default (props) => {
    return (
        <svg
            fill = 'none'
            height = { 54 }
            viewBox = '0 0 366 54'
            width = { 366 }
            { ...props }
        >
            <path
                d = 'M183.15 53.4999C283.633 53.4999 365.09 41.7384 365.09 27.2299C365.09 12.7214 283.633 0.959961 183.15 0.959961C82.6673 0.959961 1.21002 12.7214 1.21002 27.2299C1.21002 41.7384 82.6673 53.4999 183.15 53.4999Z'
                opacity = { 0.2 }
                stroke = '#6CD4F4'
                strokeMiterlimit = { 10 }
            />
        </svg>
    );
};
