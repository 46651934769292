import React from 'react';
import { withUiContext } from '../../UiContext';

// Constants
import Languages from '../../const/languages';
import Sections from '../../const/sections';

// Parts
import { Wrap, Description, Preview } from './parts';

const Info = ({ ui, history }) => {
    const { sectionId } = ui;
    const thisSection = Sections.find((section) => section.id === sectionId);
    const thisSectionAlias = thisSection.alias;
    const sectionLocale = Languages[ ui.locale ].sections;
    const sectionData = sectionLocale[ thisSectionAlias ];

    const sectionContent = (() => {
        return {
            title: sectionData.label,
            text:  sectionData.text,
        };
    })();

    const sectionCounter = (() => ({
        current: sectionId,
        total:   Sections.length,
    }))();

    return (
        <Wrap
            history = { history }
        >
            <Description
                content = { sectionContent }
                counter = { sectionCounter }
            />

            <Preview/>
        </Wrap>
    );
};

export default withUiContext(Info);
