import React, { useState } from 'react';
import { Transition } from 'react-transition-group';

// Constants
import { Config } from '../../const';

// Components
import { VolumeControlHorizontal } from '../../components';

// Parts
import { Icon } from './parts';

export default ({ volumeLevel, onVolumeChange, ...props }) => {
    const [ activity, setActivity ] = useState(false);

    return (
        <div { ...props }>
            <div className = 'volume-toggle-button'>
                <Icon
                    active = { activity }
                    onClick = { () => setActivity(!activity) }
                />

                <Transition
                    in = { activity }
                    timeout = { Config.transition.fade.duration }
                >
                    {(transitionState) => (
                        <VolumeControlHorizontal
                            className = 'control-item'
                            style = {{
                                ...Config.transition.fade.defaultStyle,
                                ...Config.transition.fade.statesStyle[ transitionState ],
                                zIndex: transitionState === 'exited' ? -1 : 1,
                            }}
                            volume = { volumeLevel }
                            onVolumeChange = { onVolumeChange }
                        />
                    )}
                </Transition>
            </div>
        </div>
    );
};
