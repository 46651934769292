import React from 'react';
import cx from 'classnames';

// Components
import { NavLinkWithoutRoute as NavLink } from '../../../components';

// Constants
import Languages from '../../../const/languages';

export default ({ ui, ...props }) => {
    return (
        <ul
            className = 'languages-list'
            { ...props }
        >
            { Object.keys(Languages).map((key) => (
                <li
                    className = { cx({
                        active: ui.locale === key,
                    }) }
                    key = { `LanguagesItem_${key}` }
                >
                    <NavLink
                        onClick = { () => ui.setLocale(key) }
                    >
                        {Languages[ key ].label}
                    </NavLink>
                </li>
            )) }
        </ul>
    );
};
