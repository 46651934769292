import React from 'react';

export default (props) => {
    return (
        <svg
            fill = 'none'
            height = { 712 }
            viewBox = '0 0 712 712'
            width = { 712 }
            { ...props }
        >
            <path
                d = 'M356 711C552.061 711 711 552.061 711 356C711 159.939 552.061 1 356 1C159.939 1 1 159.939 1 356C1 552.061 159.939 711 356 711Z'
                opacity = { 0.1 }
                stroke = 'white'
                strokeDasharray = '80 30'
                strokeMiterlimit = { 10 }
            />
            <path
                d = 'M356 598C504.012 598 624 478.012 624 330C624 181.988 504.012 62 356 62C207.988 62 88 181.988 88 330C88 478.012 207.988 598 356 598Z'
                opacity = { 0.1 }
                stroke = 'white'
                strokeMiterlimit = { 10 }
            />
            <g opacity = { 0.1 }>
                <path
                    d = 'M356 149.755C411.57 149.755 464.16 171.045 504.07 209.604C523.32 228.189 538.63 249.826 549.57 273.821C562.81 302.948 564.98 325.972 565.26 329.717C564.98 333.462 562.81 356.486 549.57 385.543C538.63 409.608 523.32 431.175 504.07 449.83C464.16 488.389 411.57 509.61 356 509.61C300.43 509.61 247.84 488.389 207.93 449.83C188.68 431.175 173.37 409.608 162.43 385.543C149.19 356.486 147.02 333.462 146.74 329.717C147.02 325.972 149.19 302.948 162.43 273.821C173.37 249.826 188.68 228.189 207.93 209.604C247.84 171.045 300.43 149.755 356 149.755ZM356 115.08C237.66 115.08 141.72 211.199 141.72 329.717C141.72 448.235 237.66 544.562 356 544.562C474.34 544.562 570.28 448.513 570.28 329.994C570.28 211.476 474.35 115.358 356 115.358V115.08Z'
                    fill = '#6CD4F4'
                    opacity = { 0.1 }
                />
            </g>
        </svg>
    );
};
