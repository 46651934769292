// Core
import React, { useState } from 'react';

// Constants
import Languages from '../../../const/languages';

export default ({ locale, handlerOnConfirm, handlerOnCancel, handlerOnAgree, passFormEnabled }) => {
    const { header, buttonConfirmLabel, buttonCancelLabel } = Languages[ locale ].dialog.onExit;
    const password = 'mda7467'
    const [ passwordVal, setPasswordVal ] = useState('')

    return (
        <div
            className = 'dialog dialog--on-exit'
        >
            <div className = 'dialog__header'>
                {passFormEnabled ? "Введите пароль" : header}
            </div>

            {passFormEnabled && 
                <div className='dialog__content'>
                    <input
                        className='dialog__input'
                        type="password"
                        label="Введите пароль" 
                        autoFocus
                        onKeyUp={ (e, v) => {
                            console.log(e.target.value)
                            setPasswordVal(e.target.value)
                        } }
                    />
                </div>
            }

            <div className = 'dialog__control'>
                <button
                    onClick = { () => {
                        if (passFormEnabled) {
                            if (passwordVal === password) handlerOnConfirm()
                        } else {
                            handlerOnAgree()
                        }
                    } }
                >
                    {buttonConfirmLabel}
                </button>

                <button
                    onClick = { handlerOnCancel }
                >
                    {passFormEnabled ? "Отмена" : buttonCancelLabel}
                </button>
            </div>
        </div>
    );
};
