import React from 'react';

// Instruments
import { withUiContext } from '../../../UiContext';

// Components
import { NavLinkWithoutRoute } from '../../../components';

// Constants
import { Languages, Sections } from '../../../const';

const VideoList = ({ ui, handleClick }) => {
    const sectionLocale = Languages[ ui.locale ].sections;

    return (
        <div className = 'video-list'>
            <div className = 'grid__row'>
                {Sections.map((section) => (
                    <div
                        className = 'grid__col'
                        key = { section.id }
                    >
                        <NavLinkWithoutRoute
                            className = 'video-list__item'
                            onClick = { () => handleClick(section.id) }
                        >
                            <div
                                className = 'video-list__item__thumb'
                                style = {{
                                    backgroundImage: `url(${require(`../../../assets/img/sections/${section.id}.jpg`)})`,
                                }}
                            />

                            <div className = 'video-list__item__label'>
                                { sectionLocale[ section.alias ].label }
                            </div>
                        </NavLinkWithoutRoute>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default withUiContext(VideoList);
