// Core
import React from 'react';

// Instruments
import { withUiContext } from '../../UiContext';
import * as routes from '../../const/routes';

// Components
import { MainMenu } from '../../components';

// Parts
import { Wrap } from './parts';

const Home = ({ ui, history }) => {
    const onSelectSectionHandler = (id) => {
        // console.log(`переход в раздел ${id}`);
        ui.setSectionId(id);
        history.push(routes.INFO);
    };

    return (
        <Wrap>
            <MainMenu onSelect = { onSelectSectionHandler } />
        </Wrap>
    );
};

export default withUiContext(Home);
