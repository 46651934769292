import React, {Component} from 'react';
import './Video.css';
import {withUiContext} from '../../UiContext';
import VideoPlayer from './VideoPlayer';
import VimeoPlayer from './VimeoPlayer';

class Video extends Component {
    render() {
        const {
            video,
            autoshow = false,
            onEnded = false,
            isMapPlaying = false,
            onTimeUpdate,
            background,
            loop,
            controls = true,
            volumeSwiper = true,
            navContent,
            withLoader,
        } = this.props;
        const {file, vimeo_id} = video;

        if (vimeo_id && vimeo_id.length) {
            return (
                <VimeoPlayer
                    vimeoId = { vimeo_id }
                    { ...this.props } />
            );
        }

        return (
            <VideoPlayer
                autoshow = { autoshow }
                background = { background }
                controls = { controls }
                isMapPlaying = { isMapPlaying }
                loop = { loop }
                navContent = { navContent }
                title = { video.title }
                video = { file }
                volumeSwiper = { volumeSwiper }
                withLoader = { withLoader }
                onEnded = { onEnded }
                onTimeUpdate = { onTimeUpdate }
            />
        );
    }
}

export default withUiContext(Video);
