import React, { Component } from 'react';

// Instruments
import { getElementOffset } from '../../lib/utils';

// Parts
import { VolumeLevels } from './parts';

export default class Volume extends Component {
    state = {
        volume: this.props.volume,
    }

    isDragging = false

    componentDidMount = () => {
        this.changeVolume(this.state.volume);
    }

    handleMousedown = (event) => {
        this.isDragging = true;
    }

    handleMouseup = (event) => {
        this.isDragging = false;
    }

    handleMousemove = (event) => {
        if (!this.isDragging) { return; }
        var percent = this.getMousePosition(event.clientX);
        this.changeVolume(percent);
    }

    getMousePosition = (clientX) => {
        const volumeWidth = this.line.offsetWidth;
        const volumeOffsetLeft = getElementOffset(this.line).left;
        const innerX = volumeOffsetLeft + volumeWidth - clientX;
        let percent = 1 - innerX / volumeWidth;

        if (percent < 0) {
            percent = 0;
        }

        if (percent > 1) {
            percent = 1;
        }

        // console.log('!!!', percent, innerX, volumeWidth);

        return percent;
    }

    handleClick = (event) => {
        var percent = this.getMousePosition(event.clientX);
        this.changeVolume(percent);
    };

    changeFill = (volume) => {
        this.fill.style.width = `${volume * 100}%`;
    }

    changeVolume = (volume) => {
        this.props.onVolumeChange(volume);
        this.changeFill(volume);
        this.setState({volume: volume});
    }

    handleButtonClick = () => {
        this.props.onToggleVolume();
        this.toggleVolume();
    }

    toggleVolume = () => {
        const { volume } = this.state;
        volume > 0 ? this.changeVolume(0) : this.changeVolume(0.5);
    }

    render() {
        // const { volume } = this.state;
        // const { navContent } = this.props;
        // let volumeOn = volume > 0;

        return (
            <div
                className = 'volume-swiper'
                style = { this.props.style }
            >
                <div
                    className = 'volume-swiper__slider volume-swiper__slider--circle-veiw'
                    ref = { (line) => this.line = line }
                    onClick = { this.handleClick }
                    onMouseDown = { this.handleMousedown }
                    onMouseMove = { this.handleMousemove }
                    onMouseUp = { this.handleMouseup }
                >
                    <div className = 'volume-swiper__slider__empty'>
                        <VolumeLevels />
                    </div>

                    <div
                        className = 'volume-swiper__slider__filled'
                        ref = { (fill) => this.fill = fill }
                    >
                        <VolumeLevels />
                    </div>
                </div>
            </div>
        );
    }
}
