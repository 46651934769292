import React, { useState } from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

// Instruments
import { withUiContext } from '../../UiContext';

// Constants
import Sections from '../../const/sections';
import * as routes from '../../const/routes';

// Parts
import { Item, Toggler } from './parts';

const SectionsNavigator = ({ ui, history }) => {
    const [ activity, setActivity ] = useState(false);
    const { playOverSound, playClickSound, sectionId } = ui;
    const onClickSectionHandler = (id) => {
        ui.setSectionId(id);
        history.push(routes.INFO);
    };
    const isSectionCurrent = (id) => sectionId === id;

    return (
        <div
            className = { cx(
                'sections-navigator',
                { active: activity },
            ) }
        >
            <Swiper
                direction = { 'vertical' }
                initialSlide = { sectionId - 1 }
                slidesPerView = { 3 }
            >
                { Sections.map(({ id, alias }) => (
                    <SwiperSlide
                        key = { `sectionsNavigatorItemId_${id}` }
                    >
                        <Item
                            alias = { alias }
                            current = { isSectionCurrent(id) }
                            id = { id }
                            onClickSectionHandler = { onClickSectionHandler }
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            <Toggler
                active = { activity }
                className = 'sections-navigator__toggler'
                playClickSound = { playClickSound }
                playOverSound = { playOverSound }
                onClick = { () => setActivity(!activity) }
            />
        </div>
    );
};

export default withUiContext(SectionsNavigator);
