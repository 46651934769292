import React  from 'react';
import { withUiContext } from '../../../UiContext';
import * as routes from '../../../const/routes';
import {
    BackToHomeButton,
    Logo,
} from '../../../components';

const Wrap = ({children, ...props}) => {
    return (
        <section
            className = 'hud hud-view'
            { ...props }
        >
            <aside className = 'hud__sidebar'>
                <div className = 'control-group'>
                    <BackToHomeButton
                        small
                        className = 'control-item'
                        to = { routes.INFO }
                    />
                </div>
            </aside>

            <main className = 'hud__content'>
                {children}

                <Logo className = 'hud__content__decor hud__content__decor-logo' />
            </main>
        </section>
    );
};

export default withUiContext(Wrap);
