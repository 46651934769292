import React from 'react';

export default (props) => {
    return (
        <svg
            fill = 'none'
            height = { 201 }
            viewBox = '0 0 200 201'
            width = { 200 }
            { ...props }
        >
            <path
                d = 'M99.81 197c53.296 0 96.5-43.205 96.5-96.5S153.106 4 99.81 4c-53.295 0-96.5 43.205-96.5 96.5S46.515 197 99.81 197z'
                stroke = '#fff'
                strokeLinecap = 'round'
                strokeMiterlimit = { 10 }
                strokeWidth = { 6.33 }
            />
            <path
                d = 'M147.53 98.92L83 47.85a2 2 0 00-3.27 1.58v102.14a1.998 1.998 0 002.237 2.021c.38-.045.738-.198 1.033-.441l64.49-51.07a2.01 2.01 0 00.794-1.57 1.994 1.994 0 00-.754-1.59z'
                fill = '#fff'
            />
        </svg>
    );
};
