import React from 'react';

export default (props) => {
    return (
        <svg
            fill = 'none'
            height = { 64 }
            viewBox = '0 0 63 64'
            width = { 63 }
            { ...props }
        >
            <path
                d = 'M45.2 30.2L28.4 16.9c-1.3-1-3.3-.1-3.3 1.6v26.6c0 1.7 1.9 2.6 3.3 1.6l16.8-13.3c1-.8 1-2.4 0-3.2z'
                fill = '#fff'
            />
            <path
                d = 'M31.5 62.3C48.345 62.3 62 48.645 62 31.8S48.345 1.3 31.5 1.3 1 14.955 1 31.8s13.655 30.5 30.5 30.5z'
                stroke = '#fff'
                strokeLinecap = 'round'
                strokeMiterlimit = { 10 }
                strokeWidth = { 2 }
            />
        </svg>
    );
};
