import appConfig from '../const/Config';

const prefix = `${process.env.PUBLIC_URL}/video/${appConfig.locale}/`;
const prefixWithoutLocale = `${process.env.PUBLIC_URL}/video/`;

export const BG = {
    file:  prefixWithoutLocale + 'index-hud.mp4',
    // vimeo_id: '376568191',
    title: '',
    name:  '',
};

export const driver = {
    file:  prefixWithoutLocale + 'driver-snd.mp4',
    // vimeo_id: '376634247',
    title: '',
    name:  '',
};


export const AboutCompany = {
    file:  prefixWithoutLocale + 'AboutCompany.mp4',
    vimeo_id: '473208394',
    title: '',
    name:  '',
};

export const DockingOfSpacecraft = {
    file:  prefixWithoutLocale + 'DockingOfSpacecraft.mp4',
    vimeo_id: '474430443',
    title: '',
    name:  '',
};

export const HighSpeedTransmissionOfSpaceInformation = {
    file:  prefixWithoutLocale + 'HighSpeedTransmissionOfSpaceInformation.mp4',
    vimeo_id: '473209427',
    title: '',
    name:  '',
};

export const MassProductionOfGeospatialProducts = {
    file:  prefixWithoutLocale + 'MassProductionOfGeospatialProducts.mp4',
    vimeo_id: '473218241',
    title: '',
    name:  '',
};

export const RadarMonitoringOfTheEarth = {
    file:  prefixWithoutLocale + 'RadarMonitoringOfTheEarth.mp4',
    vimeo_id: '473203822',
    title: '',
    name:  '',
};

export const RadioControlOfSpacecraft = {
    file:  prefixWithoutLocale + 'RadioControlOfSpacecraft.mp4',
    vimeo_id: '473211433',
    title: '',
    name:  '',
};

export const ReceptionAndProcessingOfSpaceInformation = {
    file:  prefixWithoutLocale + 'ReceptionAndProcessingOfSpaceInformation.mp4',
    vimeo_id: '473216480',
    title: '',
    name:  '',
};

export const Services = {
    file:  prefixWithoutLocale + 'Services.mp4',
    vimeo_id: '473206643',
    title: '',
    name:  '',
};

export const SpaceCommunication = {
    file:  prefixWithoutLocale + 'SpaceCommunication.mp4',
    vimeo_id: '474430871',
    title: '',
    name:  '',
};

export const Screensaver = {
    file:  prefixWithoutLocale + 'Screensaver.mp4',
    vimeo_id: '474432723',
    title: '',
    name:  '',
};
