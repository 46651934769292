import React from 'react';

// Components
import { PopupBg, PopupBgSmall, PopupBgXSmall } from './parts';

export default ({ children, title, closeHandler, size = 'default', ...props }) => {
    return (
        <div
            className = 'popup'
            { ...props }
        >
            <div className = 'popup__window'>
                <div className = 'popup__bg'>
                    { size === 'default' && (
                        <PopupBg/>
                    ) }

                    { size === 'small' && (
                        <PopupBgSmall/>
                    ) }

                    { size === 'x-small' && (
                        <PopupBgXSmall/>
                    ) }
                </div>

                <div className = 'popup__content'>
                    <div className = 'popup__header'>
                        { title && (
                            <div className = 'title'>{title}</div>
                        ) }

                        <div
                            className = 'close'
                            onClick = { closeHandler }
                        />
                    </div>

                    <div className = 'popup__body'>
                        { children }
                    </div>
                </div>
            </div>

            <div
                className = 'popup__overlay'
                onClick = { closeHandler }
            />
        </div>
    );
};
