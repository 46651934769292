import React, {Component} from 'react';
import cx from 'classnames';

// Instruments
import UiContext from '../../UiContext';

// Components
import { MobilePlaceholder, Screensaver } from '../../components';

// Assets
import './Layout.css';

import backAudio from '../../assets/audio/backSound.wav';
import overAudio from '../../assets/audio/Over2.mp3';
import overAudio1 from '../../assets/audio/Over1.mp3';
import clickAudio from '../../assets/audio/Click2.mp3';
import clickAudio1 from '../../assets/audio/Click1.mp3';
import {Config} from '../../const';

class Layout extends Component {
    constructor(props) {
        super(props);

        this.toggleDarkBg = () => {
            this.setState((state) => ({darkBg: !state.darkBg}));
        };

        this.toggleNav = (e) => {
            this.setState((prevState) => ({navExpanded: !prevState.navExpanded}));
        };

        this.playBackAudio = () => {
            let playPromise = this.backAudio.play();

            if (playPromise !== 'undefined') {
                playPromise.then(() => {
                    this.setState({backAudioPaused: false});
                }).catch((error) => {});
            }
        };

        this.pauseBackAudio = () => {
            this.backAudio.pause();
            this.setState({backAudioPaused: true});
        };

        this.toggleBackAudio = () => {
            this.backAudio.paused ? this.playBackAudio() : this.pauseBackAudio();
        };

        this.backAudioSetVolume = (volume) => {
            this.backAudio.volume = volume;
        };

        this.playOverSound = () => {
            let playPromise = this.overSound.play();
            if (playPromise !== 'undefined') {
                playPromise.then(() => {
                }).catch((error) => {});
            }
        };

        this.playClickSound = () => {
            let playPromise = this.clickSound.play();
            if (playPromise !== 'undefined') {
                playPromise.then(() => {
                }).catch((error) => {});
            }
        };

        this.playOverSound1 = () => {
            let playPromise = this.overSound1.play();
            if (playPromise !== 'undefined') {
                playPromise.then(() => {
                }).catch((error) => {});
            }
        };

        this.playClickSound1 = () => {
            let playPromise = this.clickSound1.play();
            if (playPromise !== 'undefined') {
                playPromise.then(() => {
                }).catch((error) => {});
            }
        };

        this.toggleMenu = (e) => {
            this.setState((prevState) => ({menuOpened: !prevState.menuOpened}));
        };

        this.enableBackAudioUserControl = () => {
            this.setState({isBackAudioMutedByUser: true});
        };

        this.disableBackAudioUserControl = () => {
            this.setState({isBackAudioMutedByUser: false});
        };

        this.setDriverStatus = (status) => {
            this.state.driver = status;
        };

        this.setIntroStatus = (status) => {
            this.state.intro = status;
        };

        this.setVideoEl = (el) => {
            this.setState({videoEl: el});
        };

        //
        this.setLocale = (key) => {
            this.setState({locale: key});
        };

        this.setSectionId = (id) => {
            this.setState({sectionId: id});
        };

        this.setScreensaverActive = (status) => {
            this.setState({screensaverActive: status});
        };

        this.setScreensaverTimeout = (timeout) => {
            this.setState({screensaverTimeout: timeout});
        };

        this.startScreensaverTimeout = () => {
            this.setScreensaverActive(false);
            clearTimeout(this.state.screensaverTimeout);
            const timeout = setTimeout(
                () => this.setScreensaverActive(true),
                Config.screensaver.delay,
            );
            this.setScreensaverTimeout(timeout);
        };

        this.closeWindow = () => {
            window.close();
        };

        this.state = {
            menuOpened:                  false,
            loaded:                      false,
            darkBg:                      false,
            toggleDarkBg:                this.toggleDarkBg,
            navExpanded:                 false,
            backAudioPaused:             false,
            isBackAudioMutedByUser:      false,
            driver:                      true,
            intro:                       true,
            setDriverStatus:             this.setDriverStatus,
            setIntroStatus:              this.setIntroStatus,
            toggleNav:                   this.toggleNav,
            playOverSound:               this.playOverSound,
            playClickSound:              this.playClickSound,
            playOverSound1:              this.playOverSound1,
            playClickSound1:             this.playClickSound1,
            playBackAudio:               this.playBackAudio,
            pauseBackAudio:              this.pauseBackAudio,
            toggleBackAudio:             this.toggleBackAudio,
            backAudioSetVolume:          this.backAudioSetVolume,
            toggleMenu:                  this.toggleMenu,
            enableBackAudioUserControl:  this.enableBackAudioUserControl,
            disableBackAudioUserControl: this.disableBackAudioUserControl,

            volume: {
                levelValue: 0.501,

                get level () {
                    return this.levelValue;
                },

                set level (value) {
                    this.levelValue = value;

                    return this.levelValue;
                },
            },

            videoEl:    null,
            setVideoEl: this.setVideoEl,

            //
            locale:    'ru',
            setLocale: this.setLocale,

            sectionId:    null,
            setSectionId: this.setSectionId,

            screensaverActive:    false,
            setScreensaverActive: this.setScreensaverActive,

            screensaverTimeout:    -1,
            setScreensaverTimeout: this.setScreensaverTimeout,

            closeWindow: this.closeWindow,
        };
    }

    componentDidMount = () => {
        this.playBackAudio();
        this.startScreensaverTimeout();
    }

    handleLoad = () => {
        this.setState({loaded: true});
    }

    render() {
        const {
            children,
            pageClassName = '',
        } = this.props;
        const {menuOpened, loaded, navExpanded} = this.state;

        return (
            <UiContext.Provider value = { this.state }>
                <div
                    className = { cx(
                        'page-section',
                        pageClassName,
                        menuOpened && '_menu-opened',
                        navExpanded && '_nav-expanded',
                        loaded && '_loaded',
                    ) }
                    id = 'app'
                    ref = { (page) => this.page = page }
                    style = {{
                        height: '100%',
                    }}
                    onClick = { this.startScreensaverTimeout }
                    onMouseMove = { this.startScreensaverTimeout }
                >
                    <audio
                        loop
                        ref = { (backAudio) => this.backAudio = backAudio }
                        src = { backAudio }
                    />

                    <audio
                        ref = { (overSound) => this.overSound = overSound }
                        src = { overAudio }
                    />

                    <audio
                        ref = { (clickSound) => this.clickSound = clickSound }
                        src = { clickAudio }
                    />

                    <audio
                        ref = { (overSound1) => this.overSound1 = overSound1 }
                        src = { overAudio1 }
                    />

                    <audio
                        ref = { (clickSound1) => this.clickSound1 = clickSound1 }
                        src = { clickAudio1 }
                    />

                    <MobilePlaceholder/>

                    <Screensaver
                        active = { this.state.screensaverActive }
                    />

                    {children}
                </div>
            </UiContext.Provider>
        );
    }
}

export default Layout;
